// Helpers for Margins and Paddings
@mixin generate($prefix, $property) {
  // List of sizes to generate for each
  $sizes: [ 0, 0.5, 1, 1.5, 2];
  // Spacing to multiply the sizes by
  $spacing: 1rem;

  // Loop through all of the sizes(we use @for rather than @each, as we want access to the index)
  @for $i from 1 through length($sizes) {
    // Get the size for the current index
    $size: nth($sizes, $i);

    // Create the rule
    .#{$prefix}-#{$i - 1} {
      #{$property}: $spacing * $size !important;
    }
  }
}

@include generate(m, margin);
@include generate(ml, margin-left);
@include generate(mr, margin-right);
@include generate(mt, margin-top);
@include generate(mb, margin-bottom);
@include generate(p, padding);
@include generate(pl, padding-left);
@include generate(pr, padding-right);
@include generate(pt, padding-top);
@include generate(pb, padding-bottom);

// Helpers for Display
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.flex {
  flex: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row;
}
.justify-center {
  justify-content: center;
}
.justify-stretch {
  justify-content: stretch;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
.align-self-center {
  align-self: center;
}
.align-self-start {
  align-self: flex-start;
}
.align-self-end {
  align-self: flex-end;
}

// Text Helpers
.text-red {
  color: #ff0505;
}
.text-center {
  text-align: center;
}
.font-weight-normal {
  font-weight: 400;
}
.font-weight-medium {
  font-weight: 600;
}

// Overflow
.overflow-visible {
  overflow: visible !important;
}

// Widths
.w-100 {
  width: 100%;
}

// Show/Hide transitions
.show {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.a-pointer {
  cursor: pointer;
}
.container-trazabilidad {
  position: sticky;
  z-index: 1;
    padding-top: 33px;
    margin-bottom: 5rem;
}
.swiper-pagination {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #979797;
}

.swiper-pagination-bullet-active {
  background-color: #3969E8;
}
.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

.vector-right {
  position: absolute;
  top: 0;
  right: 0;
}

.vector-left {
  position: absolute;
  top: -20%;
  width: 50vw;
}

.vector-midel {
  position: absolute;
  top: 80%;
  left: 0;
    width: 50vw;
}

.vector-midel-right {
  position: absolute;
  top: 180%;
  right: 0;
}

.vector-bottom-right {
  position: absolute;
  right: 0;
  top: 300%;
  bottom: 50%;
z-index: 2;

}
.vector-lines {
  position: absolute;
  top: 30%;
  left: 0;
  width: 50vw;
  z-index: 0;
}
.card-timeline-files {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.toast-message-body {
  background-color: white !important;
  color: black !important;
  font-size: 16px !important;
  padding: 20px 20px !important;
  line-height:20px !important;
}

.toast-message-progress {
  background-color: #34A853 !important;
}